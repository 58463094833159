import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Remarque : Routes et Route sont correctement importés
import App from "./App";
import Technicien from "./Technicien";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/technicien" element={<Technicien />} />
    </Routes>
  </Router>
);
