import React, { useEffect, useState } from "react";
import "./App.css";

function Technicien() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetch("/videos.json")
      .then((response) => response.json())
      .then((data) => setVideos(data))
      .catch((error) => console.error("Error fetching the videos:", error));
  }, []);

  return (
    <div className="App p-4">
      <h1 className="text-4xl font-bold mb-8">
        Documentation IP Energy Technicien
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {videos.map((video, index) => (
          <div
            key={index}
            className="video-item bg-white rounded-lg shadow-lg p-4"
          >
            <h3 className="text-sm font-semibold mb-4">{video.title}</h3>
            <div className="relative">
              <video controls className="w-full rounded">
                <source src={video.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <p>{video.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Technicien;
